<template>
  <div>
    <modal-seminar-download />
    <div class="d-flex justify-content-between align-items-center mb-32">
      <h3 v-if="isSeminarMode" class="m-0">Документы для загрузки</h3>
      <a href="#" @click.prevent="getTemplate()" class="docs-needed d-none d-md-block d-lg-block">
        Скачать шаблон направления
      </a>
    </div>
    <a href="#" @click.prevent="getTemplate()" class="docs-needed d-lg-none">
      Скачать шаблон направления
    </a>
    <base-upload :required="true" id="scan_trip_education" name="scan_trip_education" title="Направление на семинар Школы экспорта РЭЦ" description="Для иностранных обучающихся – перевод документов, оформленный в установленном порядке" v-model="docs.scan_trip_education" />
    <base-upload :required="false" id="scan_proxy" name="scan_proxy" title="Доверенность" description="Приложите доверенность на лицо, подписавшее направление на обучение, если данное лицо не является руководителем организации" v-model="docs.scan_proxy" />
  </div>
</template>

<script>
import BaseUpload from "@/components/form/BaseUpload.vue";
import ApiService from "../../services/api.service";
import EventOrder from "@/views/EventOrder.vue";
import ModalSeminarDownload from "@/components/ModalSeminarDownload.vue";
import $ from "jquery";
import {TokenService} from "../../services/token.service";

export default {
  name: "SeminarOrderDocs",
  components: {ModalSeminarDownload, BaseUpload},
  extends: {EventOrder},
  props: ['value', 'isSeminarMode', 'program'],
  async mounted() {
    this.docs = (await ApiService.get('userdocs/' + this.$route.params.id + '/event')).data
    this.firstLoad = false
  },
  data() {
    return {
      firstLoad: true,
      token: TokenService
          .getToken()
          .replaceAll('"', ''),
      docs: {
        scan_proxy: null,
        scan_trip_education: null
      }
    }
  },
  methods: {
    getMedia(val, field) {
      return val[field] ? val[field].id : null
    },
    async getTemplate() {
      if(this.errors.items.length > 1) {
        $('#seminar-download-doc').modal('show');
      } else {
        await this.$parent.$parent.draft()
        window.open(ApiService.baseURL() + 'api/document/template?access_token=' + this.token + '&program=' + this.program, '_blank')
      }
    }
  },
  watch: {
    docs: {
      handler: function(val) {
        if(val.id && !this.firstLoad) {
          this.$emit('input', val)
          ApiService.put('userdocs/' + val.id, {
            scan_trip_education: this.getMedia(val, 'scan_trip_education'),
            scan_proxy: this.getMedia(val, 'scan_proxy'),
          })
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import "./src/assets/style/variables";
.docs-needed {
  color: $blue-03;
  text-decoration: underline;
  position: relative;
  padding-right: 3.4rem;
  &:hover {
    text-decoration: none;
  }
  &:after {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    right: 0;
    content: "";
    top: 0;
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
      right: -12px;
    }
    background: url("/pic/profile/arrow-up-right.svg");
  }
}
</style>